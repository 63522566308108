
import { createContext, useEffect } from "react";
import toast from "react-hot-toast";
import config from "../config";
import * as axiosSOT from "../utilities/backend/axios-metadata";

export const MailContext = createContext<{
    sendEmailWrapper: (to: string, subject: string, message: string) => Promise<void>;
}>({
    sendEmailWrapper: (to: string, subject: string, message: string) => { return new Promise<void>(() => { }) },
});
export const MailProvider = (props: any) => {
    const children = props.children;
    

    const sendEmailWrapper = async (to: string, subject: string, message: string) => {
        console.log("Sending email to", to, "with subject", subject, "and message", message);
        try {
            await sendEmail(to, subject, message);
        } catch(e) {
            console.log(e);
            toast.error("Errore nell'invio della mail");
        }
    }

    const sendEmail = async (to: string, subject: string, message: string) => {
        if (to && subject && message) {
            const q = `${config.network.metadata.url}lot/email/send`;
            const body = {
                to: to,
                subject: subject,
                message: message
            }
            const res = await axiosSOT.default.post(q, body);
            console.log(res);
        } else {
            throw new Error("Parametri mancanti per l'invio della mail");
        }
    }

    return <MailContext.Provider value={{ sendEmailWrapper }}> {children} </MailContext.Provider>;
};
