import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { logger } from "../../utilities/logger/logger";
import { RootState } from '../store';
import {
    getNotificationPage,
    getUnreadNotification,
    markNotificationAsRead,
    NotificationType,
    RealtimeNotificationType
} from "../../api/notificationAPI";
import config from '../../config';
import { ConsoleNetworkOutline } from 'mdi-material-ui';


type NotificationInitialState = {
    error: null | string;
    paginationError: null | string;
    loading: boolean;
    paginationLoading: boolean;
    notificationSocketAuthenticating: boolean;
    notificationSocketAuthenticated: boolean;
    notificationSocketListening: boolean;
    notificationsOfCurrentSession: RealtimeNotificationType[]; //TODO fix type
    unreadNotificationsLoading: boolean;
    unreadNotificationsLoaded: boolean;
    loadingNotifications: boolean;
    notificationsPaginated: NotificationType[];
    loadingNotificationsFiltered: boolean;
    notificationSetReadError: null | string | Error;
    paginationHandler: boolean;
    unreadNotificationsError: null | string;
    notificationSocketAuthenticationError: null | string | Error;
    newNotfID: string | null;
};

const initialState: NotificationInitialState = {
    error: null,
    paginationError: null,
    loading: false,
    paginationLoading: false,
    notificationSocketAuthenticating: false,
    notificationSocketAuthenticated: false,
    notificationSocketListening: false,
    notificationsOfCurrentSession: [],
    unreadNotificationsLoading: false,
    unreadNotificationsLoaded: false,
    loadingNotifications: false,
    notificationsPaginated: [],
    loadingNotificationsFiltered: false,
    notificationSetReadError: null,
    paginationHandler: true,
    unreadNotificationsError: null,
    notificationSocketAuthenticationError: null,
    newNotfID: null
};


export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        paginationEnable(state) {
            state.paginationHandler = true;
        },
        paginationDisable(state) {
            state.paginationHandler = false;
        },
        paginationReset(state) {
            state.notificationsPaginated = [];
        },
        notificationGetAllMinePaginatedStart(state) {
            state.paginationError = null;
            state.paginationLoading = true;
        },
        notificationGetAllMinePaginatedSuccess(state, action: PayloadAction<{ notifications: NotificationType[] }>) {
            let newNotifications = action.payload.notifications ? action.payload.notifications : [];
            for (let not in newNotifications) {
                state.notificationsPaginated.push(newNotifications[not])
            }
            state.paginationLoading = false;
        },
        notificationGetAllMinePaginatedFail(state, action: PayloadAction<{ error: string }>) {
            state.paginationLoading = false;
            state.paginationError = action.payload.error;
        },
        notificationSocketAuthenticationStart(state) {
            state.notificationSocketAuthenticating = true;
            state.notificationSocketAuthenticated = false;
        },
        notificationSocketAuthenticationDone(state) {
            state.notificationSocketAuthenticating = false;
            state.notificationSocketAuthenticated = true;
        },
        notificationSocketAuthenticationFail(state, action: PayloadAction<{ error: string | Error }>) {
            state.notificationSocketAuthenticating = false;
            state.notificationSocketAuthenticated = false;
            state.notificationSocketAuthenticationError = action.payload.error;
        },

        notificationSocketAuthenticationReset(state) {
            state.notificationSocketAuthenticating = false;
            state.notificationSocketAuthenticated = false;
        },
        notificationSocketListening(state) {
            state.notificationSocketListening = true;
        },
        notificationSocketNotListening(state) {
            state.notificationSocketListening = false;
        },
        notificationSocketGotNewMessage(state, action: PayloadAction<{ newNotification: any }>) {//fixme
            let notifications = [...state.notificationsOfCurrentSession];
            const newNotification = action.payload.newNotification;
            let canAdd = true
            for (let notfIndex in notifications) {
                if (notifications[notfIndex]._id === newNotification._id) {
                    canAdd = false;
                }
            }
            if (canAdd) {
                notifications.push(newNotification);
                state.notificationsOfCurrentSession = notifications;
                state.newNotfID = newNotification._id
            }
        },
        notificationRemoveFromCurrentlyListed(state, action: PayloadAction<{ messageId: string }>) {
            let notifications = [...state.notificationsOfCurrentSession];
            notifications = notifications.filter((currentItem) => {
                return currentItem._id !== action.payload.messageId;
            });

            state.notificationsOfCurrentSession = notifications;
        },
        notificationRemoveAllFromCurrentlyListed(state) {
            state.notificationsOfCurrentSession = [];
        },
        notificationGetMineUnreadListStart(state) {
            state.notificationsOfCurrentSession = []
            state.unreadNotificationsLoading = true;
            state.unreadNotificationsLoaded = false;
            state.unreadNotificationsError = null;
        },
        notificationGetMineUnreadListDone(state, action: PayloadAction<{ notificationsList: RealtimeNotificationType[] }>) {
            state.unreadNotificationsLoading = false;
            state.unreadNotificationsLoaded = true;
            state.notificationsOfCurrentSession = [...action.payload.notificationsList];
        },
        notificationGetMineUnreadFail(state, action: PayloadAction<{ error: string }>) {
            state.unreadNotificationsLoading = false;
            state.unreadNotificationsLoaded = false;
            state.unreadNotificationsError = action.payload.error;
        },
        notificationsSetReadStart(state) {
            state.notificationSetReadError = null;
        },
        notificationSetReadDone(state, action: PayloadAction<{ error: Error | string | null }>) {
            state.notificationSetReadError = action.payload.error;
        },
        newNotfConsumed(state, action: PayloadAction<{ notificationID: string }>) {
            state.newNotfID = null;
        },
    }
}
);

export const {
    notificationSocketAuthenticationStart,
    notificationSocketAuthenticationDone,
    notificationSocketListening,
    notificationSocketNotListening,
    notificationSocketGotNewMessage,
    notificationRemoveFromCurrentlyListed,
    notificationGetMineUnreadListStart,
    notificationGetMineUnreadListDone,
    notificationsSetReadStart,
    notificationSetReadDone,
    notificationGetAllMinePaginatedStart,
    notificationGetAllMinePaginatedFail,
    notificationGetAllMinePaginatedSuccess,
    paginationDisable,
    paginationEnable,
    paginationReset,
    notificationGetMineUnreadFail,
    notificationSocketAuthenticationReset,
    notificationSocketAuthenticationFail,
    notificationRemoveAllFromCurrentlyListed,
    newNotfConsumed
} = notificationSlice.actions;

const notificationsToHideInCOSO = ['COIN_CREATED', 'COLLECTION_CREATED', 'TRANSFER', 'NFT_ADDED', 'COIN_SENT', 'COIN_RECEIVED', 'COIN_MINTED', 'DAO_CREATED', 'DAO_MEMBER_JOINED', 'DAO_MEMBER_KICKED', 'DAO_MEMBER_DEMOTED', 'DAO_MEMBER_PROMOTED', 'CROWDSALE', 'EXCHANGE', 'MARKETPLACE'];

export const notificationSocketAuthentication = (socket: { on: (arg0: string, arg1: { (data: any): void; (resp: { authentication: any; }): void; }) => void; emit: (arg0: string, arg1: { Authentication: string; }) => void; }) => {
    return (dispatch: Dispatch, getState: () => RootState) => {

        const token = localStorage.getItem('token');
        if (token) {
            dispatch(notificationSocketAuthenticationStart());
            const currentProfile = getState().user.currentProfile;
            if (!token || !currentProfile) {
                dispatch(notificationSocketAuthenticationFail({ error: 'user not found' }));
                return;
            }

            let daoAddress: string | null = null;
            try {
                socket.on('authentication.start', () => {
                    const auth_payload = {
                        Authentication: `Bearer ${token}`,
                        isDao: daoAddress
                    };
                    socket.emit('authentication', auth_payload,);
                    socket.on('authenticated', (auth_result) => {
                        if (auth_result.error) {
                            // handle authentication errors
                            dispatch(notificationSocketAuthenticationFail({ error: auth_result }));
                        } else {
                            dispatch(notificationSocketAuthenticationDone());
                            try {
                                dispatch(notificationSocketListening())
                                socket.on('notification.new', (msg: any) => { //fixme
                                    const eventType = JSON.parse(msg.message).eventType;
                                    const typeAllowed = !notificationsToHideInCOSO.includes(eventType);
                                    console.log(`Received notification of type: ${eventType} but must be skipped`)

                                    if (typeAllowed) {
                                        dispatch(notificationSocketGotNewMessage({ newNotification: msg }));
                                    } 
                                });
                            } catch {
                                dispatch(notificationSocketNotListening())
                            }
                        }
                    });
                });
            } catch (e) {
                logger.info(e)
                console.log(e);
                dispatch(notificationSocketAuthenticationFail({ error: 'error in socket authentication' }));
            }
        } else {
            console.log('no valid token');
            dispatch(notificationSocketAuthenticationFail({ error: 'No Valid Token' }));
        }
    }
};


export const notificationGetAllMinePagination = (page: number, amount: number) => {
    return (dispatch: Dispatch, getState: () => RootState) => {
        const userId = localStorage.getItem('userId');
        const currentProfile = getState().user.currentProfile;
        if (!userId || !currentProfile) {
            dispatch(notificationGetAllMinePaginatedFail({ error: "user not found" }));
            return;
        }
        dispatch(notificationGetAllMinePaginatedStart());

        let calendarAddress = config.smartContracts.CALENDAR_ADDRESS;

        getNotificationPage(page, amount, calendarAddress)
            .then(async response => {
                if (response.length === 0) {
                    dispatch(notificationGetAllMinePaginatedFail({ error: "no more RealTimeNotification to load" }));
                    dispatch(paginationDisable())
                    return;
                }
                dispatch(notificationGetAllMinePaginatedSuccess({ notifications: response }))
            })
            .catch(error => {
                logger.info("error", error)
                dispatch(notificationGetAllMinePaginatedFail({ error: error.response }));
            })
    }
};


export const notificationGetAllMineUnread = () => {
    return (dispatch: Dispatch, getState: () => RootState) => {
        const userId = localStorage.getItem('userId');
        dispatch(notificationGetMineUnreadListStart());
        const currentProfile = getState().user.currentProfile;
        if (!userId || !currentProfile) {
            return;
        }
        getUnreadNotification()
            .then(async response => {
                if (response.length === 0) {
                    return;
                }
                dispatch(notificationGetMineUnreadListDone({ notificationsList: response }))
            })
            .catch(error => {
                logger.info("error", error)
                dispatch(notificationGetMineUnreadFail({ error }))
            })
    }
};

export const notificationMarkAsRead = (message_notification_id: string | null) => {
    return (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(notificationsSetReadStart());
        const userId = localStorage.getItem('userId');
        const currentProfile = getState().user.currentProfile;
        if (!userId || !currentProfile) {
            return;
        }
        const notificationID = message_notification_id ? message_notification_id : 'ALL'
        markNotificationAsRead(notificationID)
            .then((response) => {
                if (response.deletedCount) {
                    logger.info('notificationMarkAsRead success');
                    dispatch(notificationSetReadDone({ error: null }));
                } else {
                    logger.info('notificationMarkAsRead failed');
                    dispatch(notificationSetReadDone({ error: response.error }));
                }
            })
            .catch(error => {
                logger.info('notificationMarkAsRead failed');
                dispatch(notificationSetReadDone({ error }));
            });
    }
};

export default notificationSlice.reducer;
