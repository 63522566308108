import { Badge, Box, Card, CardContent, Grid, Typography, makeStyles } from "@material-ui/core"
import { Item } from "../../../../types/libofthings.type"
import { ItemCardMedia } from "./ItemCardMedia";
import { useHistory } from "react-router-dom";
import { getCollectionBySymbol } from "../../../../api/nftAPI";
import { useEffect, useState } from "react";
import { CollectionData } from "../../../../types/nft.types";
import { isAddressPaused } from "../../../../store/slices/calendarSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { BlackButton } from "../../../../components/Buttons/BlackButton";
import { TransparentButton } from "../../../../components/Buttons/TransparentButton";
import { deleteItemByID } from "../../../../store/slices/libOfThingsSlice";
import toast from "react-hot-toast";

const style = makeStyles({
    card: {
        maxWidth: 240,
        border: '2px solid white',
        ['@media (max-width:600px)']: {
            height: 170,
        },
        '&:hover': {
            border: '2px solid rgba(218, 93, 62, 1)'
        }
    },

})

export const ItemCard = ({ item, callback }: {
    item: Item,
    callback: () => Promise<any>
}) => {
    const classes = style()
    const navigate = useHistory();
    const dispatch = useAppDispatch();
    const [collection, setCollection] = useState<CollectionData>();
    const [isPaused, setIsPaused] = useState<boolean>(false);
    const [isOwner, setIsOwner] = useState(false);
    const [showConfirmationRequest, setShowConfirmationRequest] = useState(false);
    const [showBin, setShowBin] = useState(false);
    const loading = useAppSelector(state => state.libOfThings.loading);
    const currentprofile = useAppSelector(state => state.user.currentProfile)
    const isUserPaused = useAppSelector(state => state.calendar.isUserPaused);

    useEffect(() => {
        const loggedUserAddress = currentprofile?.additional_properties?.commonshoodWallet;
        setIsOwner(loggedUserAddress?.toUpperCase() === item.owner.toUpperCase());
    }, [currentprofile])

    useEffect(() => {
        (async () => {
            const collection = await getCollectionBySymbol(item.collectionSymbol);
            const isPaused = await dispatch(isAddressPaused(item.owner));
            setCollection(collection);
            setIsPaused(isPaused);
        })();
    }, [item]);

    useEffect(() => {
        (async () => {
            const address = currentprofile?.additional_properties?.commonshoodWallet;
            if (address?.toUpperCase() === item.owner.toUpperCase())
                setIsPaused(isUserPaused);
        })()
    }, [isUserPaused]);


    const handleClikGoToItemPage = async () => {
        if (item != null && collection != null && item.itemID != null) {
            const collection = await getCollectionBySymbol(item.collectionSymbol);
            navigate.push(`/items/${collection.contractAddress}/${item.itemID}`);
        }
    }

    const showItemNameFixed = (name: string) => {
        const maxChars = 12;
        if (name.length > maxChars) {
            return name.slice(0, maxChars).concat('...');
        }
        return name;
    }

    const deleteItem = async () => {
        const toastId = toast.loading('Cancello l\'oggetto');
        await dispatch(deleteItemByID(item.itemID));
        toast.dismiss(toastId);
        toast.success('Oggetto cancellato');
        await callback();
    }

    useEffect(() => {
        isUrlInMyInventory();
    }, [])

    const isUrlInMyInventory = () => {
        if (window.location.href.includes('user/items')) {
            setShowBin(true);
        }
    }

    return (
        <Box>
            {
                !showConfirmationRequest ? (
                    <Card className={classes.card} style={{
                        cursor: isPaused ? '' : 'pointer',
                        borderRadius: '18px',
                    }}
                    >
                        {
                            isOwner && showBin && (
                                <Box style={{ position: 'relative' }}>
                                    <img
                                        onClick={() => {
                                            setShowConfirmationRequest(!showConfirmationRequest);
                                        }}
                                        src="../../svgs/delete-bin.svg"
                                        width={33}
                                        height={33}
                                        style={{
                                            position: 'absolute',
                                            top: 3.5,
                                            right: 3.5,
                                            zIndex: 1
                                        }}
                                    />
                                </Box>
                            )
                        }

                        <div style={{ backgroundColor: isPaused ? 'rgba(0, 0, 0, 0.3)' : '', zIndex: 1 }}
                            onClick={isPaused ? () => { } : handleClikGoToItemPage}
                        >
                            <ItemCardMedia
                                itemID={item.idInCollection}
                                collection={collection}
                                paused={isPaused}
                            />
                            <CardContent>
                                <Grid container style={{
                                    textAlign: 'center',
                                }}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" style={{
                                            position: 'relative',
                                            whiteSpace: 'nowrap',
                                            fontSize: '16px'
                                        }}>{showItemNameFixed(item.name)}</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </div>
                    </Card>
                ) : (
                    <Card className={classes.card} style={{
                        cursor: isPaused ? '' : 'pointer',
                        borderRadius: '18px',
                    }}
                    >
                        <CardContent>
                            <Grid container style={{
                                textAlign: 'center',
                            }}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" style={{
                                        position: 'relative',
                                        fontSize: '13px'
                                    }}>
                                        Sei sicuro di voler cancellare questo oggetto?
                                    </Typography>
                                    <BlackButton
                                        text="Sì, sono sicuro/a"
                                        disabled={loading}
                                        onClick={deleteItem}
                                        style={{
                                            fontSize: '12px',
                                            width: '100%',
                                            height: '30px',
                                            color: 'white',
                                            marginBottom: '6px',
                                            marginTop: '6px'
                                        }}
                                    />
                                    <TransparentButton
                                        text="No, ho cambiato idea"
                                        onClick={() => {
                                            setShowConfirmationRequest(!showConfirmationRequest)
                                        }}
                                        disabled={loading}
                                        style={{
                                            fontSize: '10px',
                                            width: '100%',
                                            height: '30px',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )
            }

        </Box>

    )
}