import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import type { ExtendedUserData, UserData } from "../../types/user.type";
import { logger } from "../../utilities/logger/logger";
import { calculateAvatar } from "../../utilities/utilities";
import { RootState } from "../store";


import { getUserDataById, getUsersList } from "../../api/userAPI";
import { getMostFrequentUsers, getMostRecentUsers } from "../../api/notificationAPI";
import { Wallet } from "ethers";

type UserInitialState = {
    user: ExtendedUserData | null,
    userIsFound: boolean | null,
    loading: boolean,
    userList: ExtendedUserData[] | null,
    userProfile: ExtendedUserData | null; //represents the user profile of the logged user
    currentProfile: ExtendedUserData | null, //represents or the user profile or one of his daos
    profilesList: (ExtendedUserData)[] | null,
    suggestedUsers: (ExtendedUserData)[] | null,
    suggestedUsersLoading: boolean,
    suggestedUsersError: string | null,
    preselectedContact: any,
    error: string | null,
    suggestedUserToShow: number
    daoType: string | null
}

const initialState: UserInitialState = {
    user: null,
    userIsFound: null,
    loading: false,
    userList: null,
    userProfile: null,
    currentProfile: null,
    profilesList: null,
    suggestedUsers: null,
    suggestedUsersError: null,
    suggestedUsersLoading: false,
    preselectedContact: null,
    error: null,
    suggestedUserToShow: 10,
    daoType: null
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userStart(state) {
            state.loading = true;
        },

        userGet(state, action: PayloadAction<{ userFound: boolean, userData: ExtendedUserData | null }>) {
            if (action.payload.userFound) {
                state.user = action.payload.userData;
                state.userIsFound = true;
                state.loading = false;
            } else {
                state.user = null;
                state.userIsFound = false;
                state.loading = false;
            }
        },

        userDelete(state) {
            state.user = null;
            state.userIsFound = null;
            state.loading = false;
            state.userList = null;
        },


        userGetListStart(state) {
            state.loading = true;
        },

        userGetListSuccess(state, action: PayloadAction<{ userList: ExtendedUserData[] }>) {
            state.userList = action.payload.userList;
            state.loading = false;
        },

        userGetListFail(state, action: PayloadAction<{ error: string }>) {
            state.loading = false;
            state.error = action.payload.error;
        },

        userSetCurrentProfile(state, action: PayloadAction<{ profile: ExtendedUserData }>) {

            state.currentProfile = action.payload.profile;
        },
        userSetUserProfile(state, action: PayloadAction<{ profile: ExtendedUserData }>) {
            state.userProfile = action.payload.profile;
        },
        userSetAllProfiles(state, action: PayloadAction<{ profilesList: (ExtendedUserData)[] }>) {
            state.profilesList = action.payload.profilesList;
        },

        userPreselectContact(state, action: PayloadAction<{ contact: any }>) {
            state.preselectedContact = action.payload.contact;
        },

        userRemovePreselectedContact(state) {
            state.preselectedContact = null;
        },
        getSuggetstedUsersStart(state) {
            state.suggestedUsers = [];
            state.suggestedUsersLoading = true;
            state.suggestedUsersError = null;
        },
        getSuggetstedUsersReset(state) {
            state.suggestedUsers = null;
            state.suggestedUsersLoading = false;
            state.suggestedUsersError = null
        },
        getSuggetstedUsersFail(state, action: PayloadAction<{ error: string }>) {
            state.suggestedUsersLoading = false;
            state.suggestedUsers = []
            state.suggestedUsersError = action.payload.error;
        },
        getSuggestedUsersSuccess(state, action: PayloadAction<{ usersList: (ExtendedUserData)[] }>) {
            state.suggestedUsers = action.payload.usersList;
            state.suggestedUsersLoading = false;
            state.suggestedUsersError = null
        },
    }
});

export const {
    userStart,
    userGet,
    userDelete,
    userGetListSuccess,
    userGetListFail,
    userSetCurrentProfile,
    userSetUserProfile,
    userSetAllProfiles,
    userPreselectContact,
    userRemovePreselectedContact,
    getSuggetstedUsersReset,
    getSuggetstedUsersStart,
    getSuggestedUsersSuccess,
    getSuggetstedUsersFail
} = userSlice.actions;


export const userGetDataFromId = (userId: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(userDelete());
        dispatch(userStart());
        try {
            let user: ExtendedUserData = await getUserDataById(userId);
            if (user != undefined) {
                user = {
                    ...user,
                    realm: 'user' as const,
                    avatar: calculateAvatar(user.email, user.name)
                };
                dispatch(userGet({ userData: user, userFound: true }))
            } else {
                logger.error(`userGetDataFromId: user with id ${userId} not found!`);
                dispatch(userGet({ userData: null, userFound: false }));
            }
        } catch (error: any) {
            dispatch(userGet({ userData: null, userFound: false }));
            logger.error('UserGetDataFromId error: ', error);
        }
    }
};

//Get user List (can exclude current logged user)
export const userGetListData = (byName: string | null) => {
    console.log('userGetListData param: ', byName);
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(userStart());

        try {
            const userList = await getUsersList();
            //filtering just users with assigned wallet
            const activeUserList = userList.filter(user => {
                //we filter also byName if necessary
                let searchMatch = true;
                if (byName !== null && byName.length > 0) {
                    searchMatch = user.name.toLowerCase().includes(byName.toLowerCase());
                }

                return searchMatch && user.additional_properties?.commonshoodWallet?.length != 0;
            });

            const avatarPopulatedList = activeUserList.map(user => {
                return {
                    ...user,
                    realm: 'user' as const,
                    icon: calculateAvatar(user.email, user.name)
                }
            });
            dispatch(userGetListSuccess({ userList: avatarPopulatedList }));
        } catch (error: any) {
            logger.error('userGetListData error:', error);
            dispatch(userGetListFail({ error }));
        }
    }
};

//Get user List by ids
export const userGetListByids = (userIds: string[]) => {
    return async (dispatch: Dispatch) => {
        dispatch(userStart());
        try {
            const userList = await getUsersList();
            //filtering just users with assigned wallet
            const activeUserList = userList.filter(user => {
                //we filter also by userID if necessary
                let searchMatch = true;
                if (userIds.length != 0) {
                    searchMatch = userIds.includes(user.id);
                }

                return searchMatch && user.additional_properties?.commonshoodWallet?.length != 0;
            });

            const avatarPopulatedList = activeUserList.map(user => {
                return {
                    ...user,
                    realm: 'user' as const,
                    icon: calculateAvatar(user.email, user.name),
                }
            });
            dispatch(userGetListSuccess({ userList: avatarPopulatedList }));
        } catch (error: any) {
            logger.error('userGetListByids error:', error);
            dispatch(userGetListFail({ error }));
        }
    }
};

const getUserDataByAddress = async (address: string) => {
    const userList = await getUsersList();
    return userList.find(user => user.additional_properties?.commonshoodWallet === address);
}

const calculateSuggestedUsers = async (suggestedUsersAddresses: string[], ethers: Wallet, currentProfileAddress: string) => {
    let suggestedUsers: (ExtendedUserData)[] = []
    const userList = await getUsersList();
    for (let i = 0; i < suggestedUsersAddresses.length; i++) {
        const user = userList.find(user => user.additional_properties?.commonshoodWallet?.toUpperCase() === suggestedUsersAddresses[i].toUpperCase());
        if (user) {
            const userObj: ExtendedUserData = {
                ...user,
                realm: 'user' as const,
                icon: calculateAvatar(user.email, user.name),
            }
            suggestedUsers.push(userObj)
        }
    }
    return suggestedUsers;
}

export const getSuggestedUsers = (mode: 0 | 1) => {
    return (dispatch: Dispatch, getState: () => RootState) => {
        const limit = getState().user.suggestedUserToShow;
        const ethers = getState().ethers.ethersInstance;
        const currentProfile = getState().user.currentProfile;
        if (!currentProfile || !ethers) {
            dispatch(getSuggetstedUsersFail({ error: " not found" }));
            return;
        }
        dispatch(getSuggetstedUsersStart());

        let daoAddress = null;
        
        if (mode === 0) {
            getMostFrequentUsers(limit, daoAddress)
                .then(async response => {
                    const profilesList = await calculateSuggestedUsers(response, ethers, currentProfile!.additional_properties!.commonshoodWallet!);
                    dispatch(getSuggestedUsersSuccess({
                        usersList: profilesList,
                    }))
                })
                .catch(error => {
                    logger.info("error", error)
                    dispatch(getSuggetstedUsersFail({ error: ' something goes wrong' }));
                }
                )
        } else if (mode === 1) {
            getMostRecentUsers(limit, daoAddress)
                .then(async response => {
                    const profilesList = await calculateSuggestedUsers(response, ethers, currentProfile!.additional_properties!.commonshoodWallet!);
                    dispatch(getSuggestedUsersSuccess({
                        usersList: profilesList
                    }))
                })
                .catch(error => {
                    logger.info("error", error)
                    dispatch(getSuggetstedUsersFail({ error: ' something goes wrong' }));
                }
                )
        }
    }
}

export default userSlice.reducer;
